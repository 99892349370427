import { MessageHandler, PageBuilderApiActionType } from '@bigcommerce/page-builder-sdk';

import { heroImageClassName } from '../../constants';
import { HeroImageProps } from '../../types/heroImageProps';
import HeroImageWidget from '../../types/heroImageWidget';

interface ListenerMessage {
    event: MessageEvent;
    widgetUuid: string;
    heroImageInstance: HeroImageWidget;
    data?: {
        action: PageBuilderApiActionType;
        widgetUuid: string;
        props: Partial<HeroImageProps>;
    };
}

const updateProps: MessageHandler = ({ data, widgetUuid, heroImageInstance }: ListenerMessage): void => {
    // We dont care about events for other widgets
    if (widgetUuid !== data.widgetUuid) {
        return;
    }

    // We dont care about non "widget prop update" events
    if (data.action !== PageBuilderApiActionType.WIDGET_PROPS_UPDATED) {
        return;
    }

    // We can skip this event if the hero image isn't in the dom
    const htmlElement = document.querySelector(heroImageClassName(widgetUuid));
    if (!htmlElement) {
        return;
    }

    const heroImageProps: HeroImageProps = {
        ...heroImageInstance.getProps(),
        ...data.props,
        _: {
            ...heroImageInstance.getProps()._,
            pageBuilderData: {
                ...heroImageInstance.getProps()._.pageBuilderData,
                ...data.props._.pageBuilderData,
                previewState: {
                    ...heroImageInstance.getProps()._.pageBuilderData.previewState,
                    ...data.props._.pageBuilderData.previewState,
                },
            },
        },
    };

    heroImageInstance.setProps(heroImageProps);
    heroImageInstance.render();
    heroImageInstance.setupContentEditable();
};

export default updateProps;

import { initTemplatePolyfill } from 'lit-html/polyfills/template_polyfill';

import HeroImage from './heroImage/heroImage';
import { HeroImageProps } from './heroImage/types/heroImageProps';
import { ToolbarItem } from './heroImage/services/inline/util';

// Get template polyfill (only needed on IE).
initTemplatePolyfill();

interface InlineEditorSettings {
    editMode: boolean;
    selector: string;
    onBlur(content: string): void;
    toolbarItems?: ToolbarItem[];
}

declare global {
    interface Window {
        BigCommerce: {
            initializeHeroImage?: (props: HeroImageProps) => HeroImage;
            inlineEditor?: {
                init(settings: InlineEditorSettings): void;
                remove(selector: string): void;
            };
        };
    }
}

window.BigCommerce = window.BigCommerce || {};
window.BigCommerce.initializeHeroImage = (props: HeroImageProps): HeroImage => {
    return new HeroImage(props);
};

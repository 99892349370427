const pageBuilderMessage = (data: object): string => {
    return JSON.stringify(data);
};

const pageBuilderApi = (widgetUuid: string, props: object): void => {
    const message = pageBuilderMessage({
        action: 'storefront-widget-props-updated',
        initiator: 'BC_STOREFRONT',
        props,
        widgetUuid,
    });
    if (window.parent) {
        window.parent.postMessage(message, '*');
    }
};

export default pageBuilderApi;

import { MessageHandler, PageBuilderApiActionType } from '@bigcommerce/page-builder-sdk';

import HeroImageWidget from '../../types/heroImageWidget';

interface ListenerMessage {
    event: MessageEvent;
    heroImageInstance: HeroImageWidget;
    data: {
        action?: PageBuilderApiActionType;
        editMode: boolean;
    };
}

const toggleEditMode: MessageHandler = ({ data, heroImageInstance }: ListenerMessage): void => {
    // We dont care about non "Update Edit Mode" actions
    if (data.action !== PageBuilderApiActionType.TOGGLE_EDIT_MODE) {
        return;
    }

    heroImageInstance.setEditMode(data.editMode);
    heroImageInstance.render();
    heroImageInstance.setupContentEditable();
    heroImageInstance.toggleVideoPlayback();
};

export default toggleEditMode;

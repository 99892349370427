import { HeroImageProps } from '../../types/heroImageProps';

interface ContentEditableConfiguration {
    editMode: boolean;
    selector: string;
    onBlur: (content: string) => void;
    toolbarItems?: ToolbarItem[];
}

export const contentEditable = (uuid: string, attr: keyof HeroImageProps): string => `content-editable-${uuid}-${attr}`;
export const contentEditableId = (uuid: string, attr: keyof HeroImageProps): string =>
    `.${contentEditable(uuid, attr)}`;

export type ToolbarItem = 'bold' | 'italic' | 'strikethrough' | 'link' | 'underline';

export const initContentEditable = ({
    editMode,
    selector,
    onBlur,
    toolbarItems,
}: ContentEditableConfiguration): void => {
    if (window.BigCommerce.inlineEditor) {
        window.BigCommerce.inlineEditor.init({
            selector,
            editMode,
            onBlur,
            toolbarItems,
        });
    }
};

export const removeInlineEditor = (selector: string): void => {
    if (window.BigCommerce.inlineEditor) {
        window.BigCommerce.inlineEditor.remove(selector);
    }
};

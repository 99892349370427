import { css } from 'goober';
import { HorizontalAlign, renderFlexBoxAlignment, ScreenSize } from '@bigcommerce/page-builder-sdk';

import { HeroImageProps } from '../../types/heroImageProps';
import renderImageHeight from '../../lib/widgetSchema/imageSize/containerHeight';
import renderContentContainerSize from '../../lib/widgetSchema/contentContainerSize/contentContainerSize';
import renderBackgroundStyles from '../../lib/widgetSchema/backgroundStyles/renderBackgroundStyles';

export const heroVideoStyle: string = css`
    width: 100%;
`;

const getVideoPadding = (alignment: HorizontalAlign, screenSize: ScreenSize): string => {
    switch (alignment) {
        case HorizontalAlign.Left:
            return `padding-left: ${screenSize === ScreenSize.Tablet ? 21 : 84}px`;
        case HorizontalAlign.Right:
            return `padding-right: ${screenSize === ScreenSize.Tablet ? 21 : 84}px`;
        default:
            return '';
    }
};

export const videoContentStyle = (props: HeroImageProps): string => css`
    position: absolute;
    display: none;
    word-break: break-word;
    word-wrap: break-word;
    height: 100%;
    ${getVideoPadding(props.contentAlignment.horizontal, ScreenSize.Mobile)};

    @media screen and (min-width: 801px) {
        display: flex;
        ${getVideoPadding(props.contentAlignment.horizontal, ScreenSize.Tablet)};
    }

    @media screen and (min-width: 1261px) {
        padding: 0 32px;
    }
`;

export const heroVideoContainerStyle = (props: HeroImageProps): string => {
    return css`
        align-items: center;
        cursor: pointer;
        display: flex;
        text-decoration: none;
        justify-content: ${renderFlexBoxAlignment(props.contentAlignment.horizontal)};
        text-align: ${renderFlexBoxAlignment(props.contentAlignment.horizontal)};
        position: relative;
        padding: 0;
        max-height: ${renderImageHeight(ScreenSize.Mobile, props.containerHeight)};
        overflow-y: hidden;

        @media screen and (min-width: 801px) {
            max-height: none;
            height: ${renderImageHeight(ScreenSize.Tablet, props.containerHeight)};
        }

        @media screen and (min-width: 1261px) {
            height: ${renderImageHeight(ScreenSize.Desktop, props.containerHeight)};
        }
    `;
};

export const heroImageContainerStyle = (props: HeroImageProps): string => {
    return css`
        align-items: center;
        cursor: pointer;
        display: flex;
        text-decoration: none;
        justify-content: ${renderFlexBoxAlignment(props.contentAlignment.horizontal)};
        text-align: center;
        position: relative;
        padding: 21px 21px 42px 21px;
        min-height: ${renderImageHeight(ScreenSize.Mobile, props.containerHeight)};

        &:after {
            content: '';
            ${renderBackgroundStyles(props)};
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: calc(${props.imageOpacity} / 100);
        }

        @media screen and (min-width: 801px) {
            max-height: none;
            min-height: ${renderImageHeight(ScreenSize.Tablet, props.containerHeight)};
            padding: 32px 42px;
        }

        @media screen and (min-width: 1261px) {
            min-height: ${renderImageHeight(ScreenSize.Desktop, props.containerHeight)};
            padding: 32px 84px;
        }
    `;
};

export const outerContentContainerStyles = (props: HeroImageProps): string => {
    // prettier-ignore
    return css`
        align-items: ${renderFlexBoxAlignment(props.contentAlignment.horizontal)};
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 21px 21px 42px 21px;
        text-align: ${props.contentAlignment.horizontal};

        @media screen and (min-width: 801px) {
            display: none;
        }
    `;
};

export const innerContentContainerStyles = (props: HeroImageProps): string => {
    // prettier-ignore
    return css`
        display: none;

        @media screen and (min-width: 801px) {
            display: flex;
            text-align: ${props.contentAlignment.horizontal};
            justify-content: center;
            flex-direction: column;
            align-items: ${renderFlexBoxAlignment(props.contentAlignment.horizontal)};
            text-align: ${props.contentAlignment.horizontal};
            width: ${renderContentContainerSize(ScreenSize.Tablet, props.contentAlignment.horizontal)};
        }

        @media screen and (min-width: 1261px) {
            display: flex;
            text-align: ${props.contentAlignment.horizontal};
            justify-content: center;
            flex-direction: column;
            align-items: ${renderFlexBoxAlignment(props.contentAlignment.horizontal)};
            text-align: ${props.contentAlignment.horizontal};
        }
    `;
};

// Flex container needed for IE11 flex to work properly
export const heroImageFlexContainer = (): string => css`
    display: flex;
    flex-direction: column;
`;

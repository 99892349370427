import { PageBuilderMessageHandler } from '@bigcommerce/page-builder-sdk';

import renderHeroImage from './services/heroImageRenderer';
import { BackgroundType, HeroImageProps } from './types/heroImageProps';
import handlers from './messageHandlers/messageHandlers';
import isEditModeDisabled from './lib/isEditModeDisabled';
import pageBuilderApi from './lib/pageBuilderApi';
import { contentEditableId, initContentEditable, removeInlineEditor } from './services/inline/util';
import { widgetSelector } from './constants';
import HeroImageWidget from './types/heroImageWidget';

export default class HeroImage implements HeroImageWidget {
    private readonly uuid: string;

    private props: HeroImageProps;

    public constructor(props: HeroImageProps) {
        this.props = props;
        this.uuid = this.props._.id;
        this.initializeEditMode(this.props, false);
        this.render();
        this.setupMessageListener();
        this.setupContentEditable();

        // Firefox Fix - will not autoplay video on initial load due to audio not being muted
        if (this.props.backgroundType === BackgroundType.VIDEO) {
            this.muteVideo();
        }
    }

    public getProps = (): HeroImageProps => this.props;

    public setupContentEditable = (): void => {
        const editMode = !isEditModeDisabled(this.getProps());
        if (!editMode) {
            this.teardownInlineEditors();
            return;
        }

        initContentEditable({
            editMode,
            selector: contentEditableId(this.uuid, 'title'),
            onBlur: (content: string): void => {
                this.setTitleInline(content);
                this.render();
                this.setupContentEditable();
            },
        });
        initContentEditable({
            editMode,
            selector: contentEditableId(this.uuid, 'subtitle'),
            onBlur: (content: string): void => {
                this.setSubTitleInline(content);
                this.render();
                this.setupContentEditable();
            },
        });
        initContentEditable({
            editMode,
            selector: contentEditableId(this.uuid, 'buttonText'),
            onBlur: (content: string): void => {
                this.setButtonTextInline(content);
                this.render();
                this.setupContentEditable();
            },
        });
    };

    public initializeEditMode = (props: HeroImageProps, editMode: boolean): void => {
        if (isEditModeDisabled(this.props)) {
            this.setProps({
                ...this.getProps(),
                _: {
                    ...this.getProps()._,
                    pageBuilderData: {
                        ...this.getProps()._.pageBuilderData,
                        previewState: {
                            editMode,
                        },
                    },
                },
            });
        }
    };

    public setProps = (props: HeroImageProps): void => {
        this.props = props;
    };

    public setEditMode = (editMode: boolean): void => {
        this.props._.pageBuilderData.previewState.editMode = editMode;
    };

    public setTitleInline = (content: string): void => {
        const currentProps = this.getProps();
        this.setProps({
            ...currentProps,
            title: content,
        });
        this.flushChanges();
    };

    public setSubTitleInline = (content: string): void => {
        const currentProps = this.getProps();
        this.setProps({
            ...currentProps,
            subtitle: content,
        });
        this.flushChanges();
    };

    public setButtonTextInline = (content: string): void => {
        const currentProps = this.getProps();
        this.setProps({
            ...currentProps,
            buttonText: content,
        });
        this.flushChanges();
    };

    public render = (): void => {
        renderHeroImage(this.getProps());
    };

    public flushChanges = (): void => pageBuilderApi(this.uuid, this.props);

    public muteVideo = (): void => {
        const element = document.querySelector(`${widgetSelector(this.uuid)} video`);
        if (!element) {
            return;
        }

        const videoElement = element as HTMLVideoElement;
        videoElement.muted = true;
    };

    public toggleVideoPlayback = (): void => {
        const element = document.querySelector(`${widgetSelector(this.uuid)} video`);
        const editMode = !isEditModeDisabled(this.getProps());

        if (!element) {
            return;
        }

        const videoElement = element as HTMLVideoElement;

        if (!editMode) {
            videoElement.play();
        } else {
            videoElement.pause();
        }
    };

    private teardownInlineEditors = (): void => {
        removeInlineEditor(contentEditableId(this.uuid, 'buttonText'));
        removeInlineEditor(contentEditableId(this.uuid, 'subtitle'));
        removeInlineEditor(contentEditableId(this.uuid, 'title'));
    };

    private setupMessageListener = (): PageBuilderMessageHandler => {
        return new PageBuilderMessageHandler({
            baseContext: {
                widgetUuid: this.uuid,
                heroImageInstance: this,
            },
            handlers,
        });
    };
}

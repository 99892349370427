export enum ImageParallaxEnabled {
    On = 'on',
    Off = 'off',
}

const renderImageParallax = (imageParallax: ImageParallaxEnabled): string => {
    return imageParallax === ImageParallaxEnabled.Off ? '' : 'background-attachment: fixed';
};

export default renderImageParallax;
